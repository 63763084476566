function WebRTCPlayer(stream_name, append) {
    this.WowzaWebRTCPlay;
    this.WebRTCState = {
        settings: {
            playSdpURL: "wss://streaming2.rentit.hu/webrtc-session.json",
            playApplicationName: "webrtc"
        }
    }
    this.videoplayer;
    this.container;
    this.statePrefix = 'play';
    this.id = stream_name;

    this.canvasPos = {
        x: 0,
        y: 0,
        w: 0,
        h: 0
    };

    this.icons =[];

    let that = this;

    this.init = function() {

        that.WowzaWebRTCPlay = new wowzawebrtc();

        that.WebRTCState.settings.playStreamName = that.id;

        that.WowzaWebRTCPlay.on({
            onError: function(error){
                let message;
                if ( error.message ) {
                    message = error.message;
                }
                else {
                    message = error
                }

                if (message.indexOf("Waiting for stream") > -1) {
                    console.log("waiting");
                    that.play();
                } else {
                    console.log("removing");
                    that.remove();
                }
            },
            onStateChanged: (state) => {
                console.log("state.connectionState", state.connectionState);
                if (state.connectionState === 'connected') {
                    that.WebRTCState.playing = true;
                } else if (state.connectionState === 'close') {
                    console.log("closed");
                } else {
                    that.WebRTCState.playing = false;
                }
            }
        });

        that.container = $("<div/>").addClass("video_container");
        that.videoplayer = $("<video/>").attr("id", that.id).attr("muted", "muted").attr("autoplay", "autoplay");

        if (append) {
            that.videoplayer.appendTo(that.container);
            that.container.appendTo($(".videoplayers"));
        }

        that.WowzaWebRTCPlay.set({
            //videoElementPlay: document.getElementById(that.id)
            videoElementPlay: that.videoplayer.get(0)
        });

        that.play();
    }

    this.play = function() {
        let sendSettings = {};
        for (let key in that.WebRTCState.settings)
        {
            let sendKey = key.substring(that.statePrefix.length);
            sendKey = sendKey[0].toLowerCase() + sendKey.slice(1);
            sendSettings[sendKey] = that.WebRTCState.settings[key];
        }

        that.WowzaWebRTCPlay.set(sendSettings);
        that.WowzaWebRTCPlay.play();

        //console.log(that);
        //that.videoplayer.get(0).play();
    }

    this.remove = function() {
        $("#"+that.id).closest(".video_container").remove();
    }

    this.addIcon = function(icon, color) {

        if (that.icons.length < 5) {
            that.icons.push({
                icon: icons[icon],
                counter: 0,
                x: that.canvasPos.x + (that.canvasPos.w / 2),
                y: that.canvasPos.y + (that.canvasPos.h / 2)
            });
        }

        /*let smiley_cont = $("<div/>").addClass("smiley").css({
            color: color
        });
        let smiley = $("<i/>").addClass("fas fa-"+icon);
        smiley.appendTo(smiley_cont);
        smiley_cont.appendTo(that.container);
        setTimeout(function (){
            smiley_cont.remove();
        }, 2200);*/
    }
}
