window.AudioContext = window.AudioContext || window.webkitAudioContext || false;

function wowzawebrtcpublish () {

    this.state = {
        ready: false,
        connectionState: 'stopped',
        videoElementPublish: undefined,
        stream: undefined,
        isScreenSharing: false,
        constraints: {
            video: false,
            audio: true,
        },
        sdpURL: '',
        streamInfo: {
            applicationName: "",
            streamName: "",
            sessionId: "[empty]"
        },
        mediaInfo: {
            videoBitrate: "",
            audioBitrate: "",
            videoFrameRate: "30",
            videoCodec: "42e01f",
            audioCodec: "opus"
        },
        userData: {param1: "value1"}, // ?
        audioEnabled: true,
        videoEnabled: false,
        useSoundMeter: false,
        cameras: [],
        microphones: []
    }
    this.soundMeter = undefined;
    this.soundMeterInterval = undefined;
    this.callbacks = {};
    this.wowzaPeerConnectionPublish = undefined;

    let that = this;

    this.setState = function (newState) {
        let _this = this;
        return new Promise((resolve, reject) => {
            that.state = {...that.state, ...newState};
            if (that.callbacks.onStateChanged != null) {
                that.callbacks.onStateChanged(that.state);
            }
            resolve(that.state);
        });
    }

    this.getState = function() {
        return that.state;
    }

    this.on = function(_callbacks) {
        that.callbacks = _callbacks;
    }

    this.set = async function(props) {
        console.log('WowzaWebRTC.set');
        console.log(props);
        let currentState = that.getState();
        let newStreamInfo = {...currentState.streamInfo};
        let newMediaInfo = {...currentState.mediaInfo};
        let newState = {};
        let constraintsChanged = false;
        if (props.videoElementPublish != null)
            newState['videoElementPublish'] = props.videoElementPublish;
        if (props.useSoundMeter != null)
            newState['useSoundMeter'] = props.useSoundMeter;
        if (props.sdpURL != null)
            newState['sdpURL'] = props.sdpURL.trim();
        if (props.applicationName != null)
            newStreamInfo['applicationName'] = props.applicationName.trim();
        if (props.streamName != null)
            newStreamInfo['streamName'] = props.streamName.trim();
        if (props.sessionId != null)
            newStreamInfo['sessionId'] = props.sessionId;
        if (props.streamInfo != null)
            newStreamInfo = {...newStreamInfo, ...props.streamInfo};
        newState['streamInfo'] = newStreamInfo;
        if (props.videoBitrate != null)
            newMediaInfo.videoBitrate = props.videoBitrate;
        if (props.audioBitrate != null)
            newMediaInfo.audioBitrate = props.audioBitrate;
        if (props.videoFrameRate != null) {
            newMediaInfo.videoFrameRate = props.videoFrameRate;
            newState['constraints'] = {...currentState.constraints};
            if (!(typeof newState['constraints']['video'] === 'boolean')) {
                if (newMediaInfo.videoFrameRate === '')
                    delete newState['constraints']['video']['frameRate'];
                else
                    newState['constraints']['video']['frameRate'] = props.videoFrameRate
            }
        }
        if (props.videoCodec != null)
            newMediaInfo.videoCodec = props.videoCodec;
        if (props.audioCodec != null)
            newMediaInfo.audioCodec = props.audioCodec;
        if (props.mediaInfo != null)
            newMediaInfo = {...newMediaInfo, ...props.streamInfo};
        newState['mediaInfo'] = newMediaInfo;
        if (props.userData != null)
            newState['userData'] = {...props.userData};
        if (props.constraints != null)
            newState['constraints'] = props.constraints;
        if (newState.constraints != null && JSON.stringify(currentState.constraints) !== JSON.stringify(newState.constraints)) {
            constraintsChanged = true;
        }

        try {
            let s1 = await this.setState(newState);
            if (s1.stream == null) {
                await that.getUserMedia();
            }
            let s2 = await that.getDevices();
            if (constraintsChanged && s2.stream != null) {
                await that.applyConstraints(s2.stream, s2.constraints);
            }
            return that.getState();
        } catch (e) {
            that.errorHandler(e);
            return null;
        }
    }

    this.applyConstraints = function(stream, constraints) {
        let promises = [];
        let audioTracks = stream.getAudioTracks();
        let videoTracks = stream.getVideoTracks();
        for (let a in audioTracks) {
            promises.push(audioTracks[a].applyConstraints(constraints['audio']));
        }
        for (let v in videoTracks) {
            promises.push(videoTracks[v].applyConstraints(constraints['video']));
        }
        return Promise.all(promises);
    }

    this.getUserMedia = function(mediaKind) {
        let _this = this;
        mediaKind = mediaKind || 'both';
        return new Promise((resolve, reject) => {
            console.log('WowzaWebRTCPublish.getUserMedia');
            let currentState = that.getState();
            let savedAudioTracks = [];
            let savedVideoTracks = [];
            if (currentState.stream != null) {
                savedAudioTracks = currentState.stream.getAudioTracks();
                savedVideoTracks = currentState.stream.getVideoTracks();
            }
            if (currentState.videoElementPublish == null) {
                reject({message: "videoElementPublish not set"});
            }

            async function getUserMediaSuccess(stream) {
                if (mediaKind === 'audio' && savedVideoTracks.length > 0) {
                    let videoTracksToRemove = stream.getVideoTracks();
                    for (let i in videoTracksToRemove) {
                        stream.removeTrack(videoTracksToRemove[i]);
                    }
                    stream.addTrack(savedVideoTracks[0]);
                } else if (mediaKind === 'video' && savedAudioTracks.length > 0) {
                    let audioTracksToRemove = stream.getAudioTracks();
                    for (let j in audioTracksToRemove) {
                        stream.removeTrack(audioTracksToRemove[j]);
                    }
                    stream.addTrack(savedAudioTracks[0]);
                }
                let newState = {stream: stream};
                if (mediaKind !== 'audio' && currentState.isScreenSharing) {
                    for (let k in savedVideoTracks) {
                        savedVideoTracks[k].stop();
                    }
                    newState['isScreenSharing'] = false;
                }
                try {
                    currentState.videoElementPublish.srcObject = stream;
                    newState['ready'] = true;
                } catch (error) {
                    console.log('getUserMediaSuccess: error connecting stream to videoElementPublish, trying createObjectURL');
                    console.log(error);
                    currentState.videoElementPublish.src = window.URL.createObjectURL(stream);
                    newState['ready'] = true;
                }
                await that.setState(newState);

                if (that.callbacks.gotDevices != null && newState['ready']) {
                    that.callbacks.gotDevices();
                }

                resolve(newState);
            }

            if (navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices.getUserMedia(currentState.constraints)
                    .then(getUserMediaSuccess)
                    .catch(that.errorHandler);
            } else if (navigator.getUserMedia) {
                navigator.getUserMedia(currentState.constraints, getUserMediaSuccess, (error) => {
                    that.errorHandler(error);
                    reject(error);
                });
            } else {
                that.errorHandler({message: "Your browser does not support WebRTC"});
                reject();
            }
        });
    }

    this.getDevices = function () {
        let _this = this;
        return new Promise((resolve, reject) => {
            console.log('WowzaWebRTCPublish.getDevices');
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                // console.log(JSON.stringify(devices));
                let constraints = {...that.getState().constraints};
                let cameras = [];
                let microphones = [];
                for (var i = 0; i < devices.length; i++) {
                    if (devices[i].kind === 'videoinput') {
                        if (cameras.length === 0) {
                            constraints.video = Object.assign({}, constraints.video, {deviceId: devices[i].deviceId});
                        }
                        cameras.push(devices[i]);
                    } else if (devices[i].kind === 'audioinput') {
                        if (microphones.length === 0) {
                            constraints.audio = Object.assign({}, constraints.audio, {deviceId: devices[i].deviceId});
                        }
                        microphones.push(devices[i]);
                    }
                }
                let newState = {cameras: cameras, microphones: microphones, constraints: constraints};

                if (that.callbacks.devicesReady != null) {
                    that.callbacks.devicesReady();
                }

                resolve(that.setState(newState));
            }).catch(
                (e) => {
                    console.log("unable to detect AV devices: " + e);
                    reject(e);
                }
            );
        });
    }

    this.onconnectionstatechange = function (evt) {
        if (evt.target != null && evt.target.connectionState != null) {
            that.setState({connectionState: evt.target.connectionState});
        }
    }

    this.onstop = function () {
        that.setState({connectionState: 'stopped'});
    }

    this.setEnabled = function(trackKind, enabled) {
        let currentState = that.getState();
        if (currentState.stream != null && currentState.stream.getTracks != null) {
            currentState.stream.getTracks().map((track) => {
                if (track.kind === trackKind) {
                    track.enabled = enabled;
                }
            });
        }
    }

    this.setMuted = function(trackKind, enabled) {
        let currentState = that.getState();
        if (currentState.stream != null && currentState.stream.getTracks != null) {
            currentState.stream.getTracks().map((track) => {
                if (track.kind === trackKind) {
                    track.muted = !enabled;
                }
            });
        }
    }

    this.setAudioEnabled = function(enabled) {
        console.log('WowzaWebRTC.setAudioEnabled:' + enabled);
        that.setEnabled("audio", enabled);
        that.setMuted("audio", enabled);
        that.setState({audioEnabled: enabled});
    }

    this.setVideoEnabled = function (enabled) {
        console.log('WowzaWebRTC.setVideoEnabled:' + enabled);
        that.setEnabled("video", enabled);
        that.setState({videoEnabled: enabled});
    }

    this.getDisplayStream = function () {
        let _this = this;
        return new Promise((resolve, reject) => {
            let savedStream = that.getState().stream;

            function getDisplaySuccess(stream, constraints) {
                let newState = {stream: stream, isScreenSharing: true};
                if (savedStream.getAudioTracks().length > 0) {
                    stream.addTrack(savedStream.getAudioTracks()[0]);
                }
                try {
                    that.getState().videoElementPublish.srcObject = stream;
                    newState['ready'] = true;
                } catch (error) {
                    reject(error);
                }
                that.setState(newState);
                resolve(stream);
            }

            let constraints = {video: true};
            if (navigator.getDisplayMedia) {
                navigator.getDisplayMedia(constraints)
                    .then((stream) => {
                        getDisplaySuccess(stream, constraints);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else if (navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia(constraints)
                    .then((stream) => {
                        getDisplaySuccess(stream, constraints);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            } else {
                constraints = {video: {mediaSource: 'screen'}};
                navigator.mediaDevices.getUserMedia(constraints)
                    .then((stream) => {
                        getDisplaySuccess(stream, constraints);
                    })
                    .catch((e) => {
                        reject(e);
                    });
            }
        });
    }

    this.setCamera = function (id) {
        let _this = this;
        console.log("WowzaWebRTC.setCamera: " + id);
        if (id === 'screen') {
            that.getDisplayStream()
                .then((stream) => {
                    let currentState = that.getState();
                    that.setEnabled("audio", currentState.audioEnabled);
                    that.setEnabled("video", currentState.videoEnabled);
                    stream.getVideoTracks()[0].onended = function () {
                        let endedState = that.getState();
                        if (endedState.cameras.length > 0) {
                            that.setCamera(endedState.cameras[0].deviceId);
                        }
                    }
                    if (_this.wowzaPeerConnectionPublish.isStarted()) {
                        that.stop();
                        that.start();
                    }
                    if (that.callbacks.onCameraChanged != null) {
                        that.callbacks.onCameraChanged('screen');
                    }
                });
        } else {
            let constraints = {...that.state.constraints};
            if (typeof constraints.video === 'boolean') {
                constraints.video = {};
            }
            constraints.video = Object.assign({}, constraints.video, {deviceId: id});
            that.setState({constraints: constraints})
                .then(() => {
                    that.getUserMedia('video')
                        .then((stream) => {
                            let currentState = that.getState();
                            that.setEnabled("audio", currentState.audioEnabled);
                            that.setEnabled("video", currentState.videoEnabled);
                            if (that.wowzaPeerConnectionPublish.isStarted()) {
                                that.stop();
                                that.start();
                            }
                            if (that.callbacks.onCameraChanged != null) {
                                that.callbacks.onCameraChanged(id);
                            }
                        });
                });
        }
    }

    this.setMicrophone = function (id) {
        let _this = this;
        console.log("WowzaWebRTC.setMicrophone: " + id);
        let constraints = {...that.state.constraints};
        if (typeof constraints.audio === 'boolean') {
            constraints.audio = {};
        }
        constraints.audio = Object.assign({}, constraints.audio, {deviceId: id});
        that.setState({constraints: constraints})
            .then(() => {
                that.getUserMedia('audio')
                    .then((stream) => {
                        let currentState = that.getState();
                        that.setEnabled("audio", currentState.audioEnabled);
                        that.setEnabled("video", currentState.videoEnabled);
                        if (that.wowzaPeerConnectionPublish.isStarted()) {
                            that.stop();
                            that.start();
                        }
                        if (that.callbacks.onMicrophoneChanged != null) {
                            that.callbacks.onMicrophoneChanged(id);
                        }
                    });
            });
    }

    this.getMicrophone = function() {
        return that.state.constraints.audio.deviceId;
    }

    this.start = function () {
        let currentState = this.getState();
        console.log('WowzaWebRTC.start()');
        console.log(currentState);
        that.wowzaPeerConnectionPublish = new WowzaPeerConnectionPublish();
        that.wowzaPeerConnectionPublish.start({
            wsURL: currentState.sdpURL,
            localStream: currentState.stream,
            streamInfo: currentState.streamInfo,
            mediaInfo: currentState.mediaInfo,
            userData: currentState.userData,
            mungeSDP: mungeSDPPublish,
            onconnectionstatechange: that.onconnectionstatechange,
            onstop: that.onstop,
            onstats: that.callbacks.onStats || undefined,
            onerror: that.errorHandler
        });
    }

    this.stop = function () {
        console.log('WowzaWebRTC.stop()');
        that.wowzaPeerConnectionPublish.stop();
    }

    this.errorHandler = function (error) {
        console.log('WowzaWebRTC ERROR:');
        console.log(error);
        if (error.message == null) {
            if (error.target != null) {
                console.log('typeof error.target: ' + typeof error.target);
            }
        }
        let newError = {...error}
        if (that.callbacks.onError != null) {
            that.callbacks.onError(error);
        }
    }
}
